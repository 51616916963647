import moment from 'moment';

export const WORK_ORDER_TAG = {
  NEW: 'NEW',
  WAITING_FOR_SUBMISSION: 'WAITING_FOR_SUBMISSION',
  WAITING_FOR_ALLOCATION: 'WAITING_FOR_ALLOCATION',
  ALLOCATED: 'ALLOCATED',
  ALLOCATION_CONFIRMATION: 'ALLOCATION_CONFIRMATION',
  ALLOCATION_CONFIRMED: 'ALLOCATION_CONFIRMED',
  ALLOCATION_REFUSED: 'ALLOCATION_REFUSED',
  WAITING_FOR_VIDEO: 'WAITING_FOR_VIDEO',
  MEDIA_SENT: 'MEDIA_SENT',
  MEDIA_RECEIVED: 'MEDIA_RECEIVED',
  MEDIA_DELIVERY_MISSED: 'MEDIA_DELIVERY_MISSED',
  WAITING_FOR_PHYSICAL_MEDIA_DELIVERY: 'WAITING_FOR_PHYSICAL_MEDIA_DELIVERY',
  WAITING_FOR_PHYSICAL_MEDIA_COLLECTION: 'WAITING_FOR_PHYSICAL_MEDIA_COLLECTION',
  PROCESSING_MEDIA: 'PROCESSING_MEDIA',
  WAITING_FOR_PHYSICAL_MEDIA_HAND_BACK: 'WAITING_FOR_PHYSICAL_MEDIA_HAND_BACK',
  WAITING_FOR_PHYSICAL_MEDIA_RETURNING: 'WAITING_FOR_PHYSICAL_MEDIA_RETURNING',
  WAITING_FOR_QUALITY_CHECK: 'WAITING_FOR_QUALITY_CHECK',
  VIDEO_PROVIDING_EXPIRED: 'VIDEO_PROVIDING_EXPIRED',
  VIDEO_CONVERSION_IN_PROGRESS: 'VIDEO_CONVERSION_IN_PROGRESS',
  VIDEO_CONVERSION_SUCCEEDED: 'VIDEO_CONVERSION_SUCCEEDED',
  VIDEO_CONVERSION_FAILED: 'VIDEO_CONVERSION_FAILED',
  WAITING_FOR_VIDEO_CONVERSION: 'WAITING_FOR_VIDEO_CONVERSION',
  WAITING_FOR_COMPLIANCE_REVIEW: 'WAITING_FOR_COMPLIANCE_REVIEW',
  COMPLIANCE_REVIEW: 'COMPLIANCE_REVIEW',
  COMPLIANCE_REVIEWED: 'COMPLIANCE_REVIEWED',
  COMPLIANCE_REVIEW_CONFIRMATION: 'COMPLIANCE_REVIEW_CONFIRMATION',
  COMPLIANCE_REVIEW_CONFIRMED: 'COMPLIANCE_REVIEW_CONFIRMED',
  COMPLIANCE_REVIEW_ACCEPTANCE: 'COMPLIANCE_REVIEW_ACCEPTANCE',
  COMPLIANCE_REVIEW_ACCEPTED: 'COMPLIANCE_REVIEW_ACCEPTED',
  WAITING_FOR_CUTS_LIST: 'WAITING_FOR_CUTS_LIST',
  SUCCEEDED: 'SUCCEEDED',
  DECLINED: 'DECLINED',
  MODIFICATION: 'MODIFICATION',
  CANCELLATION: 'CANCELLATION',
  CANCELLED: 'CANCELLED',
  ERROR: 'ERROR',
  ERROR_NO_FINANCIAL_SETTINGS: 'ERROR_NO_FINANCIAL_SETTINGS',
  CANNOT_MAKE_OFFER: 'CANNOT_MAKE_OFFER',
  WAITING_FOR_RESUBMISSION: 'WAITING_FOR_RESUBMISSION',
  RESUBMISSION: 'RESUBMISSION',
  HOLD: 'HOLD',
  FROM_FORMAL: 'FROM_FORMAL',
  PENDING_RESUBMISSION: 'PENDING_RESUBMISSION',
  WAITING_FOR_VPRC_PACKAGING: 'WAITING_FOR_VPRC_PACKAGING',
  WAITING_FOR_VPRC_PACKAGING_APPROVAL: 'WAITING_FOR_VPRC_PACKAGING_APPROVAL',
  VPRC_PACKAGING_CONFIRMED: 'VPRC_PACKAGING_CONFIRMED',
  AWAITING_PAYMENT: 'AWAITING_PAYMENT',
  PAYMENT_SETTLED: 'PAYMENT_SETTLED',
  INVOICING_FAILED: 'INVOICING_FAILED',
  CLAIMED_TIMESLOT_NOT_AVAILABLE: 'CLAIMED_TIMESLOT_NOT_AVAILABLE',
  ARCHIVE: 'ARCHIVE',
  NOT_RESEARCHED: 'NOT_RESEARCHED',
};

export const WORK_ORDER_TAG_DICTIONARY = {
  [WORK_ORDER_TAG.SUCCEEDED]: 'Completed',
  [WORK_ORDER_TAG.HOLD]: 'On Hold',
  [WORK_ORDER_TAG.WAITING_FOR_VPRC_PACKAGING]: 'Waiting for VPRC packaging',
  [WORK_ORDER_TAG.WAITING_FOR_VPRC_PACKAGING_APPROVAL]: 'Waiting for VPRC packaging approval',
  [WORK_ORDER_TAG.VPRC_PACKAGING_CONFIRMED]: 'VPRC packaging confirmed',
};

export const WORK_ORDER_STATUS = {
  ACTIVE: 'ACTIVE',
  DELETED: 'DELETED',
  HOLD: 'HOLD',
};

export const CLIENT_ORDER_STATUS = {
  ACTIVE: 'ACTIVE',
  DELETED: 'DELETED',
};

export const CLIENT_ORDER_TAGS = {
  CLOSED: 'CLOSED',
};

export const SELF_RATING_COMPLETED_STATUSES = ['COMPLETED', 'ERROR', 'COMPLETED_WITH_ERROR'];

export const DISPLAYABLE_SELF_RATING_STATUSES = {
  IN_PROGRESS: {
    label: 'In Progress',
    color: 'primary',
  },
  COMPLETED: {
    label: 'Completed',
    color: 'success',
  },
  COMPLETED_WITH_ERROR: {
    label: 'Completed With Error',
    color: 'warning',
  },
  ERROR: {
    label: 'Error',
    color: 'error',
  },
};

export const CLOSED_WORK_ORDER_TAGS = [
  WORK_ORDER_TAG.SUCCEEDED,
  WORK_ORDER_TAG.DECLINED,
  WORK_ORDER_TAG.CANCELLED,
  WORK_ORDER_TAG.CANCELLATION,
];

export const WORK_ORDER_ERROR_TAGS = [
  WORK_ORDER_TAG.ERROR,
  WORK_ORDER_TAG.ERROR_NO_FINANCIAL_SETTINGS,
  WORK_ORDER_TAG.CANNOT_MAKE_OFFER,
  WORK_ORDER_TAG.VIDEO_CONVERSION_FAILED,
];

export const INTERNAL_WARNING_MESSAGE =
  'Be aware this is an internal permission!';

export const NOTIFICATION_VARIANTS = {
  SUCCESS: 'success',
  ERROR: 'error',
  INFO: 'info',
  WARNING: 'warning',
};

export const NOTIFICATION_LEVELS = {
  GREEN: 'All new notifications (Red & Amber & Green)',
  AMBER: 'Urgent and Warning notifications (Red & Amber)',
  RED: 'Just Urgent notifications (Red)',
  NOTHING: 'Nothing',
};

export const GENRE_LIMIT = 3;

export const SUBMISSION_GENRE = {
  ACTION: 'ACTION',
  ADDITIONAL_PRODUCTION_MATERIAL: 'ADDITIONAL_PRODUCTION_MATERIAL',
  ADULT: 'ADULT',
  ADVENTURE: 'ADVENTURE',
  ANIMATION: 'ANIMATION',
  CHILDREN: 'CHILDREN',
  COMEDY: 'COMEDY',
  CRIME: 'CRIME',
  DOCUMENTARY: 'DOCUMENTARY',
  DRAMA: 'DRAMA',
  EDUCATION: 'EDUCATION',
  FANTASY: 'FANTASY',
  FILM_NOIR: 'FILM_NOIR',
  GAY_ADULT: 'GAY_ADULT',
  HORROR: 'HORROR',
  MARTIAL_ARTS: 'MARTIAL_ARTS',
  MUSIC: 'MUSIC',
  MUSICAL: 'MUSICAL',
  MYSTERY: 'MYSTERY',
  PERFORMANCE: 'PERFORMANCE',
  REALITY: 'REALITY',
  ROMANCE: 'ROMANCE',
  SCIENCE_FICTION: 'SCIENCE_FICTION',
  SPORT: 'SPORT',
  THRILLER: 'THRILLER',
  WAR: 'WAR',
  WESTERN: 'WESTERN',
  OTHER: 'OTHER',
};

export const SUBMISSION_CATEGORIES = {
  CINEMA: 'Cinema',
  HOME_ENTERTAINMENT: 'Home Entertainment',
};

export const USER_PERMISSION = {
  SYSTEM_ADMIN: 'SYSTEM_ADMIN',
  CAN_VIEW_WORKORDER_PAGE: 'CAN_VIEW_WORKORDER_PAGE',
  ADD_USER: 'ADD_USER',
  EDIT_USER: 'EDIT_USER',
  VIEW_COMPLIANCE_OFFICER: 'VIEW_COMPLIANCE_OFFICER',
  VIEW_COMPLIANCE_REVIEW_DATE: 'VIEW_COMPLIANCE_REVIEW_DATE',
  VIEW_BBFC_PUBLICATION_DATE: 'VIEW_BBFC_PUBLICATION_DATE',
  VIEW_WORKORDER_CREATION_DATE: 'VIEW_WORKORDER_CREATION_DATE',
  VIEW_FILE_ARRIVED_DATE: 'VIEW_FILE_ARRIVED_DATE',
  VIEW_QUALITY_CONTROL_COMPLETED_DATE: 'VIEW_QUALITY_CONTROL_COMPLETED_DATE',
  VIEW_VIDEO_DELIVERY_DUE_DATE: 'VIEW_VIDEO_DELIVERY_DUE_DATE',
  VIEW_REQUESTED_CLASSIFICATION_DATE: 'VIEW_REQUESTED_CLASSIFICATION_DATE',
  VIEW_RELEASE_DATE: 'VIEW_RELEASE_DATE',
  VIEW_ALLOCATED_ROOM: 'VIEW_ALLOCATED_ROOM',
  VIEW_MAM_ASSET_ID: 'VIEW_MAM_ASSET_ID',
  VIEW_WORKORDER_PRICE: 'VIEW_WORKORDER_PRICE',
  VIEW_WORKORDER_PRICE_CATEGORY: 'VIEW_WORKORDER_PRICE_CATEGORY',
  VIEW_REQUESTED_CLASSIFICATION: 'VIEW_REQUESTED_CLASSIFICATION',
  VIEW_REPORT_SUBMISSION_DEADLINE: 'VIEW_REPORT_SUBMISSION_DEADLINE',
  CAN_VIEW_TASK_PAGE: 'CAN_VIEW_TASK_PAGE',
  CAN_VIEW_RESOURCE_ALLOCATION_PAGE: 'CAN_VIEW_RESOURCE_ALLOCATION_PAGE',
  CAN_VIEW_STAFF_PAGE: 'CAN_VIEW_STAFF_PAGE',
  CAN_VIEW_FACILITIES_PAGE: 'CAN_VIEW_FACILITIES_PAGE',
  CAN_VIEW_USER_MANAGEMENT_PAGE: 'CAN_VIEW_USER_MANAGEMENT_PAGE',
  CAN_VIEW_COMPANY_PAGE: 'CAN_VIEW_COMPANY_PAGE',
  CAN_VIEW_REPORTS_PAGE: 'CAN_VIEW_REPORTS_PAGE',
  CAN_VIEW_PRICE_CALCULATOR_PAGE: 'CAN_VIEW_PRICE_CALCULATOR_PAGE',
  CAN_VIEW_ROLE_PAGE: 'CAN_VIEW_ROLE_PAGE',
  CAN_CHANGE_ASSIGNMENT: 'CAN_CHANGE_ASSIGNMENT',
  CAN_VIEW_SEARCH_PAGE: 'CAN_VIEW_SEARCH_PAGE',
  CAN_EDIT_ARCHIVE_METADATA: 'CAN_EDIT_ARCHIVE_METADATA',
  CAN_SUBMIT_WORKODER: 'CAN_SUBMIT_WORKODER',
  CAN_EDIT_COMPANY: 'CAN_EDIT_COMPANY',
  CAN_SEE_WORKORDER_HISTORY: 'CAN_SEE_WORKORDER_HISTORY',
  CAN_EDIT_WORKORDER: 'CAN_EDIT_WORKORDER',
  CAN_CANCEL_WORKORDER: 'CAN_CANCEL_WORKORDER',
  CAN_ACCEPT_CLASSIFICATION: 'CAN_ACCEPT_CLASSIFICATION',
  CAN_MODIFY_CLASSIFICATION: 'CAN_MODIFY_CLASSIFICATION',
  CAN_MODIFY_DCODE: 'CAN_MODIFY_DCODE',
  CAN_MODIFY_VPRC: 'CAN_MODIFY_VPRC',
  CAN_MODIFY_CHARITY: 'CAN_MODIFY_CHARITY',
  CAN_MODIFY_ASPERA: 'CAN_MODIFY_ASPERA',
  CAN_MODIFY_CREDIT_LIMIT: 'CAN_MODIFY_CREDIT_LIMIT',
  CAN_MODIFY_SUBMISSION_CATEGORIES: 'CAN_MODIFY_SUBMISSION_CATEGORIES',
  CAN_MODIFY_XERO_BALANCE: 'CAN_MODIFY_XERO_BALANCE',
  CAN_MODIFY_REDUCED_PHYSICAL_ASSET_DELIVERY_WINDOW: 'CAN_MODIFY_REDUCED_PHYSICAL_ASSET_DELIVERY_WINDOW',
  CAN_MODIFY_PROFORMA_INVOICE: 'CAN_MODIFY_PROFORMA_INVOICE',
  CAN_GET_NOTIFIED_ABOUT_REVIEW_ACCEPTANCE: 'CAN_GET_NOTIFIED_ABOUT_REVIEW_ACCEPTANCE',
  RESOLVE_FURTHER_INFO_REQUESTED: 'RESOLVE_FURTHER_INFO_REQUESTED',
  RESOLVE_CUTS_REQUESTED: 'RESOLVE_CUTS_REQUESTED',
  CAN_VIEW_CLIENTORDER_PAGE: 'CAN_VIEW_CLIENTORDER_PAGE',
  CAN_SUBMIT_CLIENTORDER: 'CAN_SUBMIT_CLIENTORDER',
  CAN_VIEW_ALL_CLIENTORDER: 'CAN_VIEW_ALL_CLIENTORDER',
  CAN_ADD_ROLE: 'CAN_ADD_ROLE',
  CAN_EDIT_ROLE: 'CAN_EDIT_ROLE',
  CAN_VIEW_COMPLIANCE_PLATFORM: 'CAN_VIEW_COMPLIANCE_PLATFORM',
  CAN_VIEW_ACTUAL_RUNTIME: 'CAN_VIEW_ACTUAL_RUNTIME',
  CAN_MODIFY_ACTUAL_RUNTIME: 'CAN_MODIFY_ACTUAL_RUNTIME',
  CAN_DOWNLOAD_MEDIA_ASSET: 'CAN_DOWNLOAD_MEDIA_ASSET',
  CAN_VIEW_CAST_FIELD: 'CAN_VIEW_CAST_FIELD',
  CAN_EDIT_CAST_FIELD: 'CAN_EDIT_CAST_FIELD',
  CAN_VIEW_DIRECTOR_FIELD: 'CAN_VIEW_DIRECTOR_FIELD',
  CAN_EDIT_DIRECTOR_FIELD: 'CAN_EDIT_DIRECTOR_FIELD',
  CAN_EDIT_IMDB_ID: 'CAN_EDIT_IMDB_ID',
  CAN_VIEW_DASHBOARD_PAGE: 'CAN_VIEW_DASHBOARD_PAGE',
  CAN_VIEW_DETAILED_CLASSIFICATION_RESULT: 'CAN_VIEW_DETAILED_CLASSIFICATION_RESULT',
  ADD_GOOGLE_USER: 'ADD_GOOGLE_USER',
  CAN_VIEW_FINANCIAL_INFO: 'CAN_VIEW_FINANCIAL_INFO',
  EXTERNAL_UPLOADER: 'EXTERNAL_UPLOADER',
  CAN_MANUALLY_UPDATE_RCD_AND_PRICE_CATEGORY: 'CAN_MANUALLY_UPDATE_RCD_AND_PRICE_CATEGORY',
  CAN_HOLD_SUBMISSION: 'CAN_HOLD_SUBMISSION',
  CHANGE_SUBMISSION_TO_ADVICE: 'CHANGE_SUBMISSION_TO_ADVICE',
  CAN_MODIFY_CUTS_FOR_CATEGORY: 'CAN_MODIFY_CUTS_FOR_CATEGORY',
  CAN_EDIT_PAYMENT_STATE: 'CAN_EDIT_PAYMENT_STATE',
  CAN_VIEW_PAYMENT_STATE: 'CAN_VIEW_PAYMENT_STATE',
  CAN_MODIFY_INVOICING_DATA: 'CAN_MODIFY_INVOICING_DATA',
  CAN_EDIT_NEWS_SECTION: 'CAN_EDIT_NEWS_SECTION',
  CAN_DOWNLOAD_INVOICE_PDF: 'CAN_DOWNLOAD_INVOICE_PDF',
  CAN_VIEW_ARCHIVE_PAGE: 'CAN_VIEW_ARCHIVE_PAGE',
  CAN_VIEW_TAG_PAGE: 'CAN_VIEW_TAG_PAGE',
  CAN_VIEW_TAG_SEARCH_PAGE: 'CAN_VIEW_TAG_SEARCH_PAGE',
  CAN_MANAGE_API_KEYS: 'CAN_MANAGE_API_KEYS',
  CAN_EDIT_ASPECT_RATIO: 'CAN_EDIT_ASPECT_RATIO',
  CAN_VIEW_CS_NOTES: 'CAN_VIEW_CS_NOTES',
  CAN_EDIT_CS_NOTES: 'CAN_EDIT_CS_NOTES',
  CAN_VIEW_COMMERCIAL_API_RATE_LIMIT: 'CAN_VIEW_COMMERCIAL_API_RATE_LIMIT',
  CAN_VIEW_SELF_RATING_IMPORT: 'CAN_VIEW_SELF_RATING_IMPORT',
  CAN_CREATE_SELF_RATING_IMPORT: 'CAN_CREATE_SELF_RATING_IMPORT',
  CAN_VIEW_SELF_RATING_IMPORT_HISTORY: 'CAN_VIEW_SELF_RATING_IMPORT_HISTORY',
  CAN_UPLOAD_ASSET_FILES: 'CAN_UPLOAD_ASSET_FILES',
};

export const RATING_NO_PREFERENCE = 'No preference';
export const RATINGS = {
  RATING_U: 'U',
  RATING_PG: 'PG',
  RATING_12A: '12A',
  RATING_12: '12',
  RATING_15: '15',
  RATING_18: '18',
  RATING_R18: 'R18',
};

export const dateFormat = 'DD MMM YYYY';
export const timeFormat = 'HH:mm';
export const dateTimeFormat = 'DD MMM YYYY HH:mm';

export const TASK_STATUS = {
  OPEN: 'open',
  COMPLETED: 'completed',
  FAILED: 'failed',
  CANCELLED: 'cancelled',
  TIMED_OUT: 'timedOut',
  EXPIRED: 'expired',
  HOLD: 'hold',
};

export const IMPORT_ROW_STATUS = {
  VALIDATED: 'VALIDATED',
  VALIDATION_FAILED: 'VALIDATION_FAILED',
};

export const IMPORT_ROW_STATUS_TO_CHIP = {
  VALIDATED: {
    label: 'Valid',
    color: 'success',
  },
  VALIDATION_FAILED: {
    label: 'Invalid',
    color: 'error',
  },
  ERROR: {
    label: 'Error',
    color: 'error',
  },
};

export const TASK_TYPE = {
  callServiceTask: 'callServiceTask',
  userInputTask: 'userInputTask',
};

export const FILE_UPLOAD_STATUSES = {
  INITIALIZED: 'INITIALIZED',
  IN_PROGRESS: 'IN_PROGRESS',
  FAILED: 'FAILED',
  UPLOADED: 'UPLOADED',
};

export const PROFILE_PICTURE_SIZES = {
  ORIGINAL: 'o',
  SMALL: 'sm',
  MEDIUM: 'm',
  LARGE: 'l',
};

export const PROFILE_PICTURE_ENTITY_NAMES = {
  USER: 'USER',
  COMPANY: 'COMPANY',
};

export const PROFILE_PICTURE_ENTITY_NAME_FOLDER_MAP = {
  USER: 'assets/images/profile-pictures',
  COMPANY: 'assets/images/company-logos',
};

export const CONTENT_DELIVERY_TYPES = {
  FILE: 'file',
  URL: 'url',
  PHYSICAL: 'physical',
  NONE: 'none',
};

export const WORK_ORDER_SIGNAL_NAMES = {
  MODIFICATION: 'modification',
  CANCELLATION: 'cancellation',
  FIRE_CERTIFICATE_TIMER: 'fireCertificateTimer',
  HOLD_WORKFLOW: 'holdWorkflow',
  UNHOLD_WORKFLOW: 'releaseWorkflowHold',
  CHANGE_TO_ADVICE: 'changeToAdvice',
  PAYMENT_SETTLED: 'paymentSettled',
  ADD_INVOICE_NUMBER: 'addInvoiceNumber',
  INVOICING_ERROR_RESOLVED: 'errorResolved',
  MEDIA_CLEANUP: 'mediaCleanup',
};

export const WORK_ORDER_MODIFICATION_SIGNALS = [
  WORK_ORDER_SIGNAL_NAMES.MODIFICATION,
  WORK_ORDER_SIGNAL_NAMES.PAYMENT_SETTLED,
  WORK_ORDER_SIGNAL_NAMES.ADD_INVOICE_NUMBER,
  WORK_ORDER_SIGNAL_NAMES.INVOICING_ERROR_RESOLVED,
];

export const CLIENT_ORDER_SIGNAL_NAMES = {
  COMMIT_SUBMISSIONS: 'commitSubmissions',
  CLOSE: 'close',
  GET_NEW_OFFERS: 'getNewOffers',
};

export const ASPECT_RATIOS = {
  FLAT: 'Flat (3996 x 2160)',
  STANDARD: 'Standard (2890 x 2160)',
  SCOPE: 'Scope (4096 x 1716)',
};

export const PAYMENT_STATUS = {
  NOT_PAID: 'Not paid',
  PAID: 'Paid',
};

export const PAYMENT_STATUS_MAP = {
  false: PAYMENT_STATUS.NOT_PAID,
  true: PAYMENT_STATUS.PAID,
};

export const WORK_ORDER_JOURNAL_ATTACHMENT_TYPES = {
  RESOLVE_TASK: 'resolveTask',
  UPDATE_TASK: 'updateTask',
  SIGNAL_SENT: 'signalSent',
  TASK_FAILED: 'taskFailed',
  GENERAL: 'general',
  ERROR: 'error',
  REBOOK: 'rebook',
};

export const WORK_ORDER_JOURNAL_ISSUER_TYPES = {
  USER: 'user',
  SERVICE: 'service',
};

export const SCHEDULING_ERROR_CODES = {
  INVALID_INPUT: 'invalidInput',
  INVALID_INPUT_DURATION: 'invalidInputDuration',
};

export const SELF_MEDIA_DELIVERY_FLAG = 'self';

export const NOTIFICATION_LINK_TYPE = {
  WORK_ORDER: 'workOrder',
  TASK: 'task',
  MY_TASK: 'myTask',
  WORK_ORDER_CLASSIFICATION_TAB: 'workOrderClassificationTab',
};

export const UPLOAD_ERROR = {
  RESTRICTED_FILE_NAME: 'Please use different file name for this upload',
};

export const COLLECTION_TYPES = {
  FRANCHISE_COLLECTION: 'franchise',
  FILM_COLLECTION: 'film',
  TV_SERIES: 'series',
  SEASON: 'season',
  VIDEO_GAME: 'videogame',
};

export const COLLECTION_TYPE_COLORS = {
  [COLLECTION_TYPES.FILM_COLLECTION]: 'rgba(254,238,194,0.6)',
  [COLLECTION_TYPES.TV_SERIES]: 'rgba(251,223,200,0.6)',
  [COLLECTION_TYPES.FRANCHISE_COLLECTION]: 'rgba(250,215,229,0.6)',
  [COLLECTION_TYPES.SEASON]: 'rgba(205,234,207,0.6)',
  [COLLECTION_TYPES.VIDEO_GAME]: 'rgba(250,215,229,0.6)',
};

export const EDITABLE_META_FIELDS = {
  Title: [
    {
      key: 'title',
      label: 'Title',
    },
    {
      key: 'horizon_AKATitle',
      label: 'AKA Title',
    },
    {
      key: 'horizon_InsightSectionTitles',
      label: 'Insight Section Titles',
    },
    {
      key: 'horizon_Title',
      label: 'Horizon Title',
    },
    {
      key: 'horizon_TitleV',
      label: 'Title V',
    },
    {
      key: 'horizon_collection_title',
      label: 'Collection Title',
    },
    {
      key: 'horizon_seasonTitle',
      label: 'Season Title',
    },
    {
      key: 'horizon_ClientCode',
      label: 'Client Code',
    },
    {
      key: 'horizon_ClientCode_RightsHolder',
      label: 'Rights Holder',
    },
    {
      key: 'horizon_ClientCode_SubmittingCompany',
      label: 'Submitting Company',
    },
    {
      key: 'horizon_SubmittingCompany',
      label: 'Distributor',
    },
  ],
  Rating: [
    {
      key: 'horizon_classification',
      label: 'Rating',
    },
    {
      key: 'horizon_RequestedCat',
      label: 'Requested Rating',
    },
  ],
  'Type of Record': [
    {
      key: 'horizon_DAB',
      label: 'Day After Broadcast',
    },
    {
      key: 'horizon_Form',
      label: 'Form',
    },
    {
      key: 'horizon_MainType',
      label: 'Submission Type',
    },
    {
      key: 'horizon_SubmissionType',
      label: 'Submissiong Type',
    },
    {
      key: 'horizon_SubType',
      label: 'Submission Type',
    },
    {
      key: 'horizon_productPath',
      label: 'Product path',
    },
    {
      key: 'horizon_TypeOfMedia',
      label: 'Type of Media',
    },
  ],
  'Code numbers': [
    {
      key: 'horizon_CodeNumber',
      label: 'Code Number',
    },
    {
      key: 'horizon_FragCodeNumber',
      label: 'Fragmentation Code Number',
    },
    {
      key: 'horizon_RegNumber',
      label: 'Registration Number',
    },
    {
      key: 'horizon_XCodeNumber',
      label: 'Code Number',
    },
  ],
  Credits: [
    {
      key: 'horizon_director',
      label: 'Director',
    },
    {
      key: 'horizon_cast',
      label: 'Cast',
    },
  ],
  'ECI/Insight/Consumer Advice': [
    {
      key: 'horizon_shortFormInsight',
      label: 'Content advice', // Content advice was Short Ratings Info
    },
    {
      key: 'horizon_ConAdvLanguage',
      label: 'Language',
    },
    {
      key: 'horizon_ConAdvSex',
      label: 'Sex',
    },
    {
      key: 'horizon_ConAdvTheme',
      label: 'Other',
    },
    {
      key: 'horizon_ConAdvViolence',
      label: 'Violence',
    },
    {
      key: 'horizon_InsightSpoilers',
      label: 'Ratings Info contains spoilers?',
    },
    {
      key: 'horizon_synopsis',
      label: 'Synopsis',
    },
    {
      key: 'horizon_eci1Web',
      label: 'ECI web',
    },
    {
      key: 'horizon_nonDefiningIssues',
      label: 'Non Defining Issues',
    },
  ],
  Runtimes: [
    {
      key: 'horizon_runtimeInSeconds',
      label: 'Runtime (seconds)',
    },
    {
      key: 'horizon_CFilmFootage',
      label: 'Cut Film Footage',
    },
    {
      key: 'horizon_CutRT',
      label: 'Cut Runtime',
    },
    {
      key: 'horizon_FtAdd',
      label: 'Footage Added',
    },
    {
      key: 'horizon_FtApp',
      label: 'Passed Footage',
    },
    {
      key: 'horizon_FtDel',
      label: 'Footage Deleted',
    },
    {
      key: 'horizon_PCutRT',
      label: 'Pre-cut Runtime',
    },
    {
      key: 'horizon_RFinaltot',
      label: 'Passed Footage',
    },
    {
      key: 'horizon_RSubRT',
      label: 'Resubmission Runtime',
    },
    {
      key: 'horizon_actualRuntimeInSeconds',
      label: 'Approved Runtime (seconds)',
    },
    {
      key: 'horizon_SubRT',
      label: 'Submitted Runtime',
    },
  ],
  'Additional Material': [
    {
      key: 'horizon_AlreadyIn3D',
      label: 'Already Passed in 3D?',
    },
    {
      key: 'horizon_ApprovalType',
      label: 'VPRC Approval',
    },
    {
      key: 'horizon_Artist',
      label: 'Artist',
    },
    {
      key: 'horizon_ArtworkForm',
      label: 'Form in which Submitted',
    },
    {
      key: 'horizon_BreakRemarks',
      label: 'Break Remarks',
    },
    {
      key: 'horizon_CCO',
      label: 'Caption Check Only',
    },
    {
      key: 'horizon_Colour',
      label: 'Colour',
    },
    {
      key: 'horizon_Contains2Dversion',
      label: 'Contains 2D Version',
    },
    {
      key: 'horizon_classificationDate',
      label: 'Date of Classification',
    },
    {
      key: 'horizon_DateCutsIssued',
      label: 'Date Cuts Issued',
    },
    {
      key: 'horizon_DateCutsListApproval',
      label: 'Date Cuts Approved',
    },
    {
      key: 'horizon_DateEmbargo',
      label: 'Embargo Date',
    },
    {
      key: 'horizon_DateERApproval',
      label: 'Examiners Report Approved on',
    },
    {
      key: 'horizon_DateExaminationComplete',
      label: 'Examination Completed on',
    },
    {
      key: 'horizon_DateFurtherViewing',
      label: 'Further Viewing on',
    },
    {
      key: 'horizon_DateInterimSig',
      label: 'Interim Clearance Form Received on',
    },
    {
      key: 'horizon_DateModification',
      label: 'Date Modification Letter Sent',
    },
    {
      key: 'horizon_DateModificationRejection',
      label: 'Date Modification Rejection Letter Sent',
    },
    {
      key: 'horizon_DatePassed',
      label: 'Date Approved',
    },
    {
      key: 'horizon_DateRefer',
      label: 'Date Referred',
    },
    {
      key: 'horizon_DateReject',
      label: 'Date Rejected',
    },
    {
      key: 'horizon_releaseDate',
      label: 'Release Date',
    },
    {
      key: 'horizon_publicationDate',
      label: 'Publication Date',
    },
    {
      key: 'horizon_DateReleaseUK',
      label: 'UK Release Date',
    },
    {
      key: 'horizon_DateResub',
      label: 'Date Resubmitted',
    },
    {
      key: 'horizon_DateWithdrawn',
      label: 'Withdrawal Date',
    },
    {
      key: 'horizon_distributor',
      label: 'Distributor',
    },
    {
      key: 'horizon_episodeTitle',
      label: 'Episode Title',
    },
    {
      key: 'horizon_episode',
      label: 'Episode Number',
    },
    {
      key: 'horizon_season',
      label: 'Season Number',
    },
    {
      key: 'horizon_FinalExaminers',
      label: 'Final Examiners',
    },
    {
      key: 'horizon_FinalTot',
      label: 'Submitted Footage',
    },
    {
      key: 'horizon_FragmentedWork',
      label: 'Fragmentation Work',
    },
    {
      key: 'horizon_FragmentOnDiscNo',
      label: 'Fragment on Disc Number',
    },
    {
      key: 'horizon_Gauge',
      label: 'Film Gauge',
    },
    {
      key: 'horizon_genre',
      label: 'Genre',
      type: 'multiSelect',
      options: SUBMISSION_GENRE,
    },
    {
      key: 'horizon_ICFRemarks',
      label: 'Remarks',
    },
    {
      key: 'horizon_iserveComment',
      label: 'Iserve Comment',
    },
    {
      key: 'horizon_language',
      label: 'Language',
    },
    {
      key: 'horizon_NoOfFilmReels',
      label: 'Number of Film Reels',
    },
    {
      key: 'horizon_additionalLanguages',
      label: 'Other Language',
    },
    {
      key: 'horizon_otherlanguage',
      label: 'Other Language',
    },
    {
      key: 'horizon_otherlanguages',
      label: 'Other Languages',
    },
    {
      key: 'horizon_PaperworkRemarks',
      label: 'Paperwork Remarks',
    },
    {
      key: 'horizon_PaperworkRemarksFree',
      label: 'Paperwork Remarks Free Text',
    },
    {
      key: 'horizon_PassedBytes',
      label: 'Passed Bytes',
    },
    {
      key: 'horizon_platformUniqueID',
      label: 'Platform Unique ID',
    },
    {
      key: 'horizon_producer',
      label: 'Producer',
    },
    {
      key: 'horizon_ProductionYear',
      label: 'Production Year',
    },
    {
      key: 'horizon_year',
      label: 'Year',
    },
    {
      key: 'horizon_aspectRatio',
      label: 'Ratio',
    },
    {
      key: 'horizon_ReasonCut',
      label: 'Reason for cuts to packaging submission',
    },
    {
      key: 'horizon_RecordFormat',
      label: 'Record Format',
    },
    {
      key: 'horizon_RecordStandard',
      label: 'Recording Standard',
    },
    {
      key: 'horizon_Remarks',
      label: 'Remarks',
    },
    {
      key: 'horizon_RightsHolder',
      label: 'Rights Holder',
    },
    {
      key: 'horizon_RightsHolderDCode',
      label: 'Rights Holder Company Code',
    },
    {
      key: 'horizon_ScreenSubTitle',
      label: 'Screen sub title',
    },
    {
      key: 'horizon_ScreenTitle',
      label: 'Title on Screen',
    },
    {
      key: 'horizon_skyWarningLanguage',
      label: 'Sky - Language',
    },
    {
      key: 'horizon_skyWarningMature',
      label: 'Sky - Mature',
    },
    {
      key: 'horizon_skyWarningSexNudity',
      label: 'Sky - Sex/Nudity',
    },
    {
      key: 'horizon_skyWarningViolence',
      label: 'Sky - Violence',
    },
    {
      key: 'horizon_Sound',
      label: 'Sound',
    },
    {
      key: 'horizon_Track',
      label: 'Track',
    },
    {
      key: 'horizon_Untitled',
      label: 'Date Classification',
    },
    {
      key: 'horizon_version',
      label: 'Version',
    },
    {
      key: 'horizon_versionName',
      label: 'Version Name',
    },
    {
      key: 'horizon_versionNumber',
      label: 'Version Number',
    },
    {
      key: 'horizon_VMConfirmTime',
      label: 'Virgin Media - Confirm Time',
    },
    {
      key: 'horizon_VMCustomerRT',
      label: 'Virgin Media - Customer RT',
    },
    {
      key: 'horizon_VMCutsInfo',
      label: 'Virgin Media - Cuts Info',
    },
    {
      key: 'horizon_VMDatePassed',
      label: 'Virgin Media - Date Passed',
    },
    {
      key: 'horizon_VMEMTCategory',
      label: 'Virgin Media - EMT Category',
    },
    {
      key: 'horizon_VMFILENAME',
      label: 'Virgin Media - Filename',
    },
    {
      key: 'horizon_VMOfficer',
      label: 'Virgin Media - Officer',
    },
    {
      key: 'horizon_VMProposedCategory',
      label: 'Virgin Media - Proposed Category',
    },
    {
      key: 'horizon_VMrefno',
      label: 'Virgin Media - Ref Number',
    },
    {
      key: 'horizon_VMruntime',
      label: 'Virgin Media - Runtime',
    },
    {
      key: 'horizon_WorkFormat',
      label: 'Work Format',
    },
    {
      key: 'horizon_status',
      label: 'Status',
      type: 'multiSelect',
      options: {
        Pending: 'Pending',
        Dead: 'Dead',
        Backlog: 'Backlog',
        Rejected: 'Rejected',
        Withdrawn: 'Withdrawn',
        Advice: 'Advice',
        Cut: 'Cut',
        Completed: 'Completed',
      },
      default: { value: '', label: 'N/A' },
    },
    {
      key: 'horizon_statusDescription',
      label: 'Status description',
    },
  ],
  'Composite Elements': [
    {
      key: 'horizon_CompositeTitles',
      label: 'Composite Titles',
    },
    {
      key: 'horizon_archive',
      label: 'Archive',
      type: 'select',
      options: [
        { value: 'true', label: 'Yes' },
        { value: 'false', label: 'No' },
      ],
      default: { value: 'false', label: 'No' },
    },
    {
      key: 'horizon_tvmeta_CRA_DateImport',
      label: 'CRA Date Import',
    },
    {
      key: 'horizon_tvmeta_CRA_type',
      label: 'CRA Type',
    },
    {
      key: 'horizon_tvmeta_ep_cat',
      label: 'Category',
    },
    {
      key: 'horizon_TVMETA_EP_DISC',
      label: 'Episode Disc',
    },
    {
      key: 'horizon_tvmeta_ep_eci',
      label: 'Episode ECI',
    },
    {
      key: 'horizon_tvmeta_ep_marker',
      label: 'Episode Marker',
    },
    {
      key: 'horizon_TVMETA_EP_SERIES_TITLE',
      label: 'EP Series Title',
    },
    {
      key: 'horizon_TVMETA_EP_TIMECODE',
      label: 'EP Timecode',
    },
    {
      key: 'horizon_TVMETA_EP_TYPE',
      label: 'Type',
    },
    {
      key: 'horizon_TVMETA_EP_UNIQUEID',
      label: 'Unique ID',
    },
    {
      key: 'horizon_TVMETA_EP_VOLUME',
      label: 'Volume',
    },
    {
      key: 'horizon_tvmeta_series_id',
      label: 'Series ID',
    },
    {
      key: 'horizon_tvmeta_series_name',
      label: 'Series Name',
    },
  ],
  'Viewing Info': [
    {
      key: 'horizon_AdviceViewing',
      label: 'Advice Viewing',
      type: 'select',
      options: [
        { value: 'true', label: 'Yes' },
        { value: 'false', label: 'No' },
      ],
      default: { value: 'false', label: 'No' },
    },
    {
      key: 'horizon_AlternateDC',
      label: 'Alternate Distributor Change',
    },
    {
      key: 'horizon_distributor',
      label: 'Distributor Change',
    },
    {
      key: 'horizon_FYITitle',
      label: 'FYI',
    },
    {
      key: 'horizon_MATCHESCODENUMBER',
      label: 'Matches',
    },
    {
      key: 'horizon_MatchesRegNumber',
      label: 'Matches',
    },
    {
      key: 'horizon_MP4_URL',
      label: 'URL',
    },
    {
      key: 'horizon_OtherMedium',
      label: 'Passed in another medium?',
    },
    {
      key: 'horizon_RTFlags',
      label: 'Viewing info',
    },
    {
      key: 'horizon_savedAccessDetails',
      label: 'Saved Access Details',
    },
    {
      key: 'horizon_savedPW',
      label: 'Saved Password',
    },
    {
      key: 'horizon_savedURL',
      label: 'Saved URL',
    },
    {
      key: 'horizon_savedUserName',
      label: 'Saved Username',
    },
    {
      key: 'horizon_SecondaryClassification',
      label: 'Secondary Classification',
    },
    {
      key: 'horizon_TC',
      label: 'Technical Comparison',
    },
    {
      key: 'horizon_TC_3D2D',
      label: '3D to 2D Technical Comparison',
    },
    {
      key: 'horizon_TitleChange',
      label: 'Title Change',
    },
    {
      key: 'horizon_VODAdditional',
      label: 'VoD Additional details',
    },
    {
      key: 'horizon_VODDeliveryMethod',
      label: 'VoD Delivery Method',
    },
    {
      key: 'horizon_VODPW',
      label: 'VoD Password',
    },
    {
      key: 'horizon_VODUSER',
      label: 'VoD Username',
    },
  ],
  'Viewing History Menu': [
    {
      key: 'horizon_AgeCheck',
      label: 'Age Check',
    },
    {
      key: 'horizon_AsCutOnFilm',
      label: 'As Cut On Film?',
    },
    {
      key: 'horizon_cutComment',
      label: 'Cut Comment',
    },
    {
      key: 'horizon_CutsDescription',
      label: 'Cuts Description',
    },
    {
      key: 'horizon_CutsListApproval',
      label: 'Cuts List Approval',
    },
    {
      key: 'horizon_CutsReplaced',
      label: 'Cuts Replaced?',
    },
    {
      key: 'horizon_CutsSummary',
      label: 'Cuts Summary',
    },
    {
      key: 'horizon_CutVersion',
      label: 'CutVersion',
    },
    {
      key: 'horizon_DateViewing',
      label: 'Viewing Date',
    },
    {
      key: 'horizon_EMTAction',
      label: 'CM Action',
    },
    {
      key: 'horizon_EMTAdjudication',
      label: 'CM Adjudication',
    },
    {
      key: 'horizon_EMTCategory',
      label: 'CM Category',
    },
    {
      key: 'horizon_Examiners',
      label: 'COs',
    },
    {
      key: 'horizon_FlashingLetter',
      label: 'Flashing Lights Letter issued?',
    },
    {
      key: 'horizon_FullViewing',
      label: 'Full Viewing',
    },
    {
      key: 'horizon_FurtherViewing',
      label: 'Further Viewing',
    },
    {
      key: 'horizon_MonthRemarks',
      label: 'Web Remarks',
    },
    {
      key: 'horizon_Officer',
      label: 'Approved by',
    },
    {
      key: 'horizon_OtherExaminers',
      label: 'Other COs',
    },
    {
      key: 'horizon_ProposedCategory',
      label: 'Proposed Category',
    },
    {
      key: 'horizon_Referral',
      label: 'Referred To',
    },
    {
      key: 'horizon_RejectedBy',
      label: 'Rejected By',
    },
    {
      key: 'horizon_RequestedCatDetails',
      label: 'Requested Category',
    },
    {
      key: 'horizon_SectionsViewed',
      label: 'Sections Viewed',
    },
    {
      key: 'horizon_TypeCuts',
      label: 'Type of Cuts',
    },
    {
      key: 'horizon_VE1STDATE',
      label: 'First Viewing Date',
    },
    {
      key: 'horizon_vh_cuts',
      label: 'VH Cuts',
    },
    {
      key: 'horizon_classificationDate',
      label: 'VH Date',
    },
    {
      key: 'horizon_vh_examiners',
      label: 'VH Examiners',
    },
    {
      key: 'horizon_vh_examtime',
      label: 'VH Exam Time',
    },
    {
      key: 'horizon_vh_no',
      label: 'VH No',
    },
    {
      key: 'horizon_vh_remarks',
      label: 'VH Remarks',
    },
    {
      key: 'horizon_vh_rtt',
      label: 'VH RTT',
    },
    {
      key: 'horizon_Viewing',
      label: 'Viewing Count',
    },
  ],
  URLs: [
    {
      key: 'horizon_posterUri',
      label: 'Poster URL',
    },
    {
      key: 'horizon_trailerUri',
      label: 'Trailer URL',
    },
    {
      key: 'horizon_source',
      label: 'Source URL',
    },
  ],
};

export const SUBMISSION_HIDDEN_FIELDS = ['releaseDate'];

export const INTEGER_REGEX_MAX_9999 = /^^(?:[1-9]\d{0,3}|9999)?$/;

export const INTEGER_REGEX_MIN_0_MAX_9999 = /^(?:0|[1-9]\d{0,3}|9999)?$/;

export const ZERO_VALUE_ALLOWED_FIELDS = ['episode'];

const VALUE_PARSER = {
  toNumber: (input) => {
    if (!input) return null;
    const value = Number(input);
    if (Number.isNaN(value) || !INTEGER_REGEX_MAX_9999.test(value)) return 1;
    return value;
  },
  toUpperCaseArray: (input = []) => input.map((item) => item.toUpperCase()),
};

export const EDITABLE_NON_FRANCHISE_COLLECTION_META_FIELDS = {
  Title: [
    {
      key: 'title',
      label: 'Title',
    },
    {
      key: 'imdbId',
      label: 'IMDb Identifier',
    },
  ],
  Credits: [
    {
      key: 'director',
      label: 'Director',
    },
    {
      key: 'cast',
      label: 'Cast',
    },
  ],
  'ECI/Insight/Consumer Advice': [
    {
      key: 'shortFormInsight',
      label: 'Content advice', // Content advice was Short Ratings Info
    },
    {
      key: 'synopsis',
      label: 'Synopsis',
    },
  ],
  'Additional Material': [
    {
      key: 'classificationDate',
      label: 'Date of Classification',
    },
    {
      key: 'releaseDate',
      label: 'Release Date',
    },
    {
      key: 'distributor',
      label: 'Distributor',
    },
    {
      key: 'genre',
      label: 'Genre',
      type: 'multiSelect',
      options: SUBMISSION_GENRE,
      render: VALUE_PARSER.toUpperCaseArray,
    },
    {
      key: 'language',
      label: 'Language',
    },
    {
      key: 'season',
      label: 'Season Number',
      render: VALUE_PARSER.toNumber,
    },
  ],
  Rating: [
    {
      key: 'classification',
      label: 'Rating',
    },
  ],
  Runtimes: [
    {
      key: 'runtime',
      label: 'Runtime (minutes)',
      render: VALUE_PARSER.toNumber,
    },
  ],
  'Viewing Info': [
    {
      key: 'AdviceViewing',
      label: 'Advice Viewing',
      type: 'select',
      options: [
        { value: 'true', label: 'Yes' },
        { value: 'false', label: 'No' },
      ],
      default: { value: 'false', label: 'No' },
    },
  ],
  'Type of Record': [
    {
      key: 'productPath',
      label: 'Product path',
    },
    {
      key: 'collection_type',
      label: 'Collection type',
      type: 'select',
      options: [
        { value: 'franchise', label: 'Franchise' },
        { value: 'film', label: 'Film' },
        { value: 'series', label: 'Tv Series' },
        { value: 'season', label: 'Season' },
        { value: 'videogame', label: 'Video game' },
      ],
    },
  ],
  URLs: [
    {
      key: 'posterUri',
      label: 'Poster URL',
    },
    {
      key: 'trailerUri',
      label: 'Trailer URL',
    },
    {
      key: 'source',
      label: 'Source URL',
    },
  ],
};

export const EDITABLE_FRANCHISE_COLLECTION_META_FIELDS = {
  Title: [
    {
      key: 'title',
      label: 'Title',
    },
    {
      key: 'imdbId',
      label: 'IMDb Identifier',
    },
  ],
  Credits: [
    {
      key: 'director',
      label: 'Director',
    },
    {
      key: 'cast',
      label: 'Cast',
    },
  ],
  'Additional Material': [
    {
      key: 'genre',
      label: 'Genre',
      type: 'multiSelect',
      options: SUBMISSION_GENRE,
    },
  ],
  Rating: [
    {
      key: 'classification',
      label: 'Rating',
    },
  ],
  'Viewing Info': [
    {
      key: 'AdviceViewing',
      label: 'Advice Viewing',
      type: 'select',
      options: [
        { value: 'true', label: 'Yes' },
        { value: 'false', label: 'No' },
      ],
      default: { value: 'false', label: 'No' },
    },
  ],
  'Type of Record': [
    {
      key: 'collection_type',
      label: 'Collection type',
      type: 'select',
      options: [
        { value: 'franchise', label: 'Franchise' },
        { value: 'film', label: 'Film' },
        { value: 'series', label: 'Tv Series' },
        { value: 'season', label: 'Season' },
        { value: 'videogame', label: 'Video game' },
      ],
    },
  ],
  URLs: [
    {
      key: 'posterUri',
      label: 'Poster URL',
    },
    {
      key: 'trailerUri',
      label: 'Trailer URL',
    },
    {
      key: 'source',
      label: 'Source URL',
    },
  ],
};

export const EDITABLE_META_FIELDS_V2 = {
  Title: [
    {
      key: 'title',
      label: 'Title',
    },
    {
      label: 'EIDR',
      key: 'eidr',
      tooltip:
        <p>
          This is the only representation that can properly be called a DOI or
          an EIDR ID. The canonical form of an EIDR ID is <br />
          <strong>10.5240/XXXX-XXXX-XXXX-XXXX-XXXX-C</strong> <br />
          where <br />
          <strong>X</strong> is a hexadecimal digit (0-9, A-F) <br />
          <strong>C</strong> is the ISO 7064 Mod 37,36 check character. <br />
        </p>,
    },
    {
      label: 'IMDb Identifier',
      key: 'imdbId',
    },
    {
      key: 'AKATitle',
      label: 'AKA Title',
    },
    {
      key: 'InsightSectionTitles',
      label: 'Insight Section Titles',
    },
    {
      key: 'Title',
      label: 'Horizon Title',
    },
    {
      key: 'TitleV',
      label: 'Title V',
    },
    {
      key: 'collection_title',
      label: 'Collection Title',
    },
    {
      key: 'seasonTitle',
      label: 'Season Title',
    },
    {
      key: 'ClientCode',
      label: 'Client Code',
    },
    {
      key: 'ClientCode_RightsHolder',
      label: 'Rights Holder',
    },
    {
      key: 'ClientCode_SubmittingCompany',
      label: 'Submitting Company',
    },
    {
      key: 'SubmittingCompany',
      label: 'Distributor',
    },
  ],
  Rating: [
    {
      key: 'classification',
      label: 'Rating',
    },
    {
      key: 'RequestedCat',
      label: 'Requested Rating',
    },
  ],
  'Type of Record': [
    {
      key: 'DAB',
      label: 'Day After Broadcast',
    },
    {
      key: 'Form',
      label: 'Form',
    },
    {
      key: 'MainType',
      label: 'Submission Type',
    },
    {
      key: 'SubmissionType',
      label: 'Submissiong Type',
    },
    {
      key: 'SubType',
      label: 'Submission Type',
    },
    {
      key: 'productPath',
      label: 'Product path',
    },
    {
      key: 'TypeOfMedia',
      label: 'Type of Media',
    },
  ],
  'Code numbers': [
    {
      key: 'CodeNumber',
      label: 'Code Number',
    },
    {
      key: 'FragCodeNumber',
      label: 'Fragmentation Code Number',
    },
    {
      key: 'registrationNum',
      label: 'Registration Number',
    },
    {
      key: 'XCodeNumber',
      label: 'Code Number',
    },
  ],
  Credits: [
    {
      key: 'director',
      label: 'Director',
    },
    {
      key: 'cast',
      label: 'Cast',
    },
  ],
  'ECI/Insight/Consumer Advice': [
    {
      key: 'shortFormInsight',
      label: 'Content advice', // Content advice was Short Ratings Info
    },
    {
      key: 'ConAdvLanguage',
      label: 'Language',
    },
    {
      key: 'ConAdvSex',
      label: 'Sex',
    },
    {
      key: 'ConAdvTheme',
      label: 'Other',
    },
    {
      key: 'ConAdvViolence',
      label: 'Violence',
    },
    {
      key: 'InsightSpoilers',
      label: 'Ratings Info contains spoilers?',
    },
    {
      key: 'synopsis',
      label: 'Synopsis',
    },
    {
      key: 'eci1Web',
      label: 'ECI web',
    },
    {
      key: 'nonDefiningIssues',
      label: 'Non Defining Issues',
    },
  ],
  Runtimes: [
    {
      key: 'runtimeInSeconds',
      label: 'Runtime (seconds)',
    },
    {
      key: 'CFilmFootage',
      label: 'Cut Film Footage',
    },
    {
      key: 'CutRT',
      label: 'Cut Runtime',
    },
    {
      key: 'FtAdd',
      label: 'Footage Added',
    },
    {
      key: 'FtApp',
      label: 'Passed Footage',
    },
    {
      key: 'FtDel',
      label: 'Footage Deleted',
    },
    {
      key: 'PCutRT',
      label: 'Pre-cut Runtime',
    },
    {
      key: 'RFinaltot',
      label: 'Passed Footage',
    },
    {
      key: 'RSubRT',
      label: 'Resubmission Runtime',
    },
    {
      key: 'actualRuntimeInSeconds',
      label: 'Approved Runtime (seconds)',
    },
    {
      key: 'SubRT',
      label: 'Submitted Runtime',
    },
  ],
  'Additional Material': [
    {
      key: 'AlreadyIn3D',
      label: 'Already Passed in 3D?',
    },
    {
      key: 'ApprovalType',
      label: 'VPRC Approval',
    },
    {
      key: 'Artist',
      label: 'Artist',
    },
    {
      key: 'ArtworkForm',
      label: 'Form in which Submitted',
    },
    {
      key: 'BreakRemarks',
      label: 'Break Remarks',
    },
    {
      key: 'CCO',
      label: 'Caption Check Only',
    },
    {
      key: 'Colour',
      label: 'Colour',
    },
    {
      key: 'Contains2Dversion',
      label: 'Contains 2D Version',
    },
    {
      key: 'classificationDate',
      label: 'Date of Classification',
    },
    {
      key: 'DateCutsIssued',
      label: 'Date Cuts Issued',
    },
    {
      key: 'DateCutsListApproval',
      label: 'Date Cuts Approved',
    },
    {
      key: 'DateEmbargo',
      label: 'Embargo Date',
    },
    {
      key: 'DateERApproval',
      label: 'Examiners Report Approved on',
    },
    {
      key: 'DateExaminationComplete',
      label: 'Examination Completed on',
    },
    {
      key: 'DateFurtherViewing',
      label: 'Further Viewing on',
    },
    {
      key: 'DateInterimSig',
      label: 'Interim Clearance Form Received on',
    },
    {
      key: 'DateModification',
      label: 'Date Modification Letter Sent',
    },
    {
      key: 'DateModificationRejection',
      label: 'Date Modification Rejection Letter Sent',
    },
    {
      key: 'DatePassed',
      label: 'Date Approved',
    },
    {
      key: 'DateRefer',
      label: 'Date Referred',
    },
    {
      key: 'DateReject',
      label: 'Date Rejected',
    },
    {
      key: 'releaseDate',
      label: 'Release Date',
    },
    {
      key: 'publicationDate',
      label: 'Publication Date',
    },
    {
      key: 'DateReleaseUK',
      label: 'UK Release Date',
    },
    {
      key: 'DateResub',
      label: 'Date Resubmitted',
    },
    {
      key: 'DateWithdrawn',
      label: 'Withdrawal Date',
    },
    {
      key: 'distributor',
      label: 'Distributor',
    },
    {
      key: 'episodeTitle',
      label: 'Episode Title',
    },
    {
      key: 'episode',
      label: 'Episode Number',
    },
    {
      key: 'episodeNo',
      label: 'Episode No',
    },
    {
      key: 'season',
      label: 'Season Number',
    },
    {
      key: 'seasonNo',
      label: 'Season No',
    },
    {
      key: 'FinalExaminers',
      label: 'Final Examiners',
    },
    {
      key: 'FinalTot',
      label: 'Submitted Footage',
    },
    {
      key: 'FragmentedWork',
      label: 'Fragmentation Work',
    },
    {
      key: 'FragmentOnDiscNo',
      label: 'Fragment on Disc Number',
    },
    {
      key: 'Gauge',
      label: 'Film Gauge',
    },
    {
      key: 'genre',
      label: 'Genre',
      type: 'multiSelect',
      options: SUBMISSION_GENRE,
    },
    {
      key: 'ICFRemarks',
      label: 'Remarks',
    },
    {
      key: 'iserveComment',
      label: 'Iserve Comment',
    },
    {
      key: 'language',
      label: 'Language',
    },
    {
      key: 'NoOfFilmReels',
      label: 'Number of Film Reels',
    },
    {
      key: 'additionalLanguages',
      label: 'Other Language',
    },
    {
      key: 'otherlanguage',
      label: 'Other Language',
    },
    {
      key: 'otherlanguages',
      label: 'Other Languages',
    },
    {
      key: 'PaperworkRemarks',
      label: 'Paperwork Remarks',
    },
    {
      key: 'PaperworkRemarksFree',
      label: 'Paperwork Remarks Free Text',
    },
    {
      key: 'PassedBytes',
      label: 'Passed Bytes',
    },
    {
      key: 'platformUniqueID',
      label: 'Platform Unique ID',
    },
    {
      key: 'producer',
      label: 'Producer',
    },
    {
      key: 'ProductionYear',
      label: 'Production Year',
    },
    {
      key: 'year',
      label: 'Year',
    },
    {
      key: 'aspectRatio',
      label: 'Ratio',
    },
    {
      key: 'ReasonCut',
      label: 'Reason for cuts to packaging submission',
    },
    {
      key: 'RecordFormat',
      label: 'Record Format',
    },
    {
      key: 'RecordStandard',
      label: 'Recording Standard',
    },
    {
      key: 'Remarks',
      label: 'Remarks',
    },
    {
      key: 'RightsHolder',
      label: 'Rights Holder',
    },
    {
      key: 'RightsHolderDCode',
      label: 'Rights Holder Company Code',
    },
    {
      key: 'ScreenSubTitle',
      label: 'Screen sub title',
    },
    {
      key: 'ScreenTitle',
      label: 'Title on Screen',
    },
    {
      key: 'skyWarningLanguage',
      label: 'Sky - Language',
    },
    {
      key: 'skyWarningMature',
      label: 'Sky - Mature',
    },
    {
      key: 'skyWarningSexNudity',
      label: 'Sky - Sex/Nudity',
    },
    {
      key: 'skyWarningViolence',
      label: 'Sky - Violence',
    },
    {
      key: 'Sound',
      label: 'Sound',
    },
    {
      key: 'Track',
      label: 'Track',
    },
    {
      key: 'Untitled',
      label: 'Date Classification',
    },
    {
      key: 'version',
      label: 'Version',
    },
    {
      key: 'versionName',
      label: 'Version Name',
    },
    {
      key: 'versionNumber',
      label: 'Version Number',
    },
    {
      key: 'VMConfirmTime',
      label: 'Virgin Media - Confirm Time',
    },
    {
      key: 'VMCustomerRT',
      label: 'Virgin Media - Customer RT',
    },
    {
      key: 'VMCutsInfo',
      label: 'Virgin Media - Cuts Info',
    },
    {
      key: 'VMDatePassed',
      label: 'Virgin Media - Date Passed',
    },
    {
      key: 'VMEMTCategory',
      label: 'Virgin Media - EMT Category',
    },
    {
      key: 'VMFILENAME',
      label: 'Virgin Media - Filename',
    },
    {
      key: 'VMOfficer',
      label: 'Virgin Media - Officer',
    },
    {
      key: 'VMProposedCategory',
      label: 'Virgin Media - Proposed Category',
    },
    {
      key: 'VMrefno',
      label: 'Virgin Media - Ref Number',
    },
    {
      key: 'VMruntime',
      label: 'Virgin Media - Runtime',
    },
    {
      key: 'WorkFormat',
      label: 'Work Format',
    },
    {
      key: 'status',
      label: 'Status',
      type: 'multiSelect',
      options: {
        Pending: 'Pending',
        Dead: 'Dead',
        Backlog: 'Backlog',
        Rejected: 'Rejected',
        Withdrawn: 'Withdrawn',
        Advice: 'Advice',
        Cut: 'Cut',
        Completed: 'Completed',
      },
      default: { value: '', label: 'N/A' },
    },
    {
      key: 'statusDescription',
      label: 'Status description',
    },
  ],
  'Composite Elements': [
    {
      key: 'CompositeTitles',
      label: 'Composite Titles',
    },
    {
      key: 'archive',
      label: 'Archive',
      type: 'select',
      options: [
        { value: 'true', label: 'Yes' },
        { value: 'false', label: 'No' },
      ],
      default: { value: 'false', label: 'No' },
    },
    {
      key: 'tvmeta_CRA_DateImport',
      label: 'CRA Date Import',
    },
    {
      key: 'tvmeta_CRA_type',
      label: 'CRA Type',
    },
    {
      key: 'tvmeta_ep_cat',
      label: 'Category',
    },
    {
      key: 'TVMETA_EP_DISC',
      label: 'Episode Disc',
    },
    {
      key: 'tvmeta_ep_eci',
      label: 'Episode ECI',
    },
    {
      key: 'tvmeta_ep_marker',
      label: 'Episode Marker',
    },
    {
      key: 'TVMETA_EP_NO',
      label: 'EP Episode Number',
    },
    {
      key: 'TVMETA_EP_SEASON_NO',
      label: 'EP Season Number',
    },
    {
      key: 'TVMETA_EP_SERIES_TITLE',
      label: 'EP Series Title',
    },
    {
      key: 'TVMETA_EP_TIMECODE',
      label: 'EP Timecode',
    },
    {
      key: 'TVMETA_EP_TYPE',
      label: 'Type',
    },
    {
      key: 'TVMETA_EP_UNIQUEID',
      label: 'Unique ID',
    },
    {
      key: 'TVMETA_EP_VOLUME',
      label: 'Volume',
    },
    {
      key: 'tvmeta_series_id',
      label: 'Series ID',
    },
    {
      key: 'tvmeta_series_name',
      label: 'Series Name',
    },
  ],
  'Viewing Info': [
    {
      key: 'AdviceViewing',
      label: 'Advice Viewing',
      type: 'select',
      options: [
        { value: 'true', label: 'Yes' },
        { value: 'false', label: 'No' },
      ],
      default: { value: 'false', label: 'No' },
    },
    {
      key: 'AlternateDC',
      label: 'Alternate Distributor Change',
    },
    {
      key: 'distributor',
      label: 'Distributor Change',
    },
    {
      key: 'FYITitle',
      label: 'FYI',
    },
    {
      key: 'MATCHESCODENUMBER',
      label: 'Matches',
    },
    {
      key: 'MatchesRegNumber',
      label: 'Matches',
    },
    {
      key: 'MP4_URL',
      label: 'URL',
    },
    {
      key: 'OtherMedium',
      label: 'Passed in another medium?',
    },
    {
      key: 'RTFlags',
      label: 'Viewing info',
    },
    {
      key: 'savedAccessDetails',
      label: 'Saved Access Details',
    },
    {
      key: 'savedPW',
      label: 'Saved Password',
    },
    {
      key: 'savedURL',
      label: 'Saved URL',
    },
    {
      key: 'savedUserName',
      label: 'Saved Username',
    },
    {
      key: 'SecondaryClassification',
      label: 'Secondary Classification',
    },
    {
      key: 'TC',
      label: 'Technical Comparison',
    },
    {
      key: 'TC_3D2D',
      label: '3D to 2D Technical Comparison',
    },
    {
      key: 'TitleChange',
      label: 'Title Change',
    },
    {
      key: 'VODAdditional',
      label: 'VoD Additional details',
    },
    {
      key: 'VODDeliveryMethod',
      label: 'VoD Delivery Method',
    },
    {
      key: 'VODPW',
      label: 'VoD Password',
    },
    {
      key: 'VODUSER',
      label: 'VoD Username',
    },
  ],
  'Viewing History Menu': [
    {
      key: 'AgeCheck',
      label: 'Age Check',
    },
    {
      key: 'AsCutOnFilm',
      label: 'As Cut On Film?',
    },
    {
      key: 'cutComment',
      label: 'Cut Comment',
    },
    {
      key: 'CutsDescription',
      label: 'Cuts Description',
    },
    {
      key: 'CutsListApproval',
      label: 'Cuts List Approval',
    },
    {
      key: 'CutsReplaced',
      label: 'Cuts Replaced?',
    },
    {
      key: 'CutsSummary',
      label: 'Cuts Summary',
    },
    {
      key: 'CutVersion',
      label: 'CutVersion',
    },
    {
      key: 'DateViewing',
      label: 'Viewing Date',
    },
    {
      key: 'EMTAction',
      label: 'CM Action',
    },
    {
      key: 'EMTAdjudication',
      label: 'CM Adjudication',
    },
    {
      key: 'EMTCategory',
      label: 'CM Category',
    },
    {
      key: 'Examiners',
      label: 'COs',
    },
    {
      key: 'FlashingLetter',
      label: 'Flashing Lights Letter issued?',
    },
    {
      key: 'FullViewing',
      label: 'Full Viewing',
    },
    {
      key: 'FurtherViewing',
      label: 'Further Viewing',
    },
    {
      key: 'MonthRemarks',
      label: 'Web Remarks',
    },
    {
      key: 'Officer',
      label: 'Approved by',
    },
    {
      key: 'OtherExaminers',
      label: 'Other COs',
    },
    {
      key: 'ProposedCategory',
      label: 'Proposed Category',
    },
    {
      key: 'Referral',
      label: 'Referred To',
    },
    {
      key: 'RejectedBy',
      label: 'Rejected By',
    },
    {
      key: 'RequestedCatDetails',
      label: 'Requested Category',
    },
    {
      key: 'SectionsViewed',
      label: 'Sections Viewed',
    },
    {
      key: 'TypeCuts',
      label: 'Type of Cuts',
    },
    {
      key: 'VE1STDATE',
      label: 'First Viewing Date',
    },
    {
      key: 'vh_cuts',
      label: 'VH Cuts',
    },
    {
      key: 'classificationDate',
      label: 'VH Date',
    },
    {
      key: 'vh_examiners',
      label: 'VH Examiners',
    },
    {
      key: 'vh_examtime',
      label: 'VH Exam Time',
    },
    {
      key: 'vh_no',
      label: 'VH No',
    },
    {
      key: 'vh_remarks',
      label: 'VH Remarks',
    },
    {
      key: 'vh_rtt',
      label: 'VH RTT',
    },
    {
      key: 'Viewing',
      label: 'Viewing Count',
    },
    {
      key: 'ExamAction',
      label: 'Exam action',
      multiline: true,
    },
    {
      key: 'NoteOnFile',
      label: 'Notes on file',
      type: 'richText',
    },
  ],
  URLs: [
    {
      key: 'posterUri',
      label: 'Poster URL',
    },
    {
      key: 'trailerUri',
      label: 'Trailer URL',
    },
    {
      key: 'source',
      label: 'Source URL',
    },
  ],
};

export const BATCH_EDIT_FIELDS = {
  Title: [
    {
      key: 'seasonTitle',
      label: 'Season Title',
    },
  ],
  'Type of Record': [
    {
      key: 'productPath',
      label: 'Product path',
    },
  ],
  'Additional Material': [
    {
      key: 'season',
      label: 'Season Number',
    },
    {
      key: 'genre',
      label: 'Genre',
      type: 'multiSelect',
      options: SUBMISSION_GENRE,
    },
    {
      key: 'status',
      label: 'Status',
      type: 'select',
      options: [
        { value: '', label: 'N/A' },
        { value: 'Dead', label: 'Dead' },
        { value: 'Backlog', label: 'Backlog' },
        { value: 'Rejected', label: 'Rejected' },
        { value: 'Withdrawn', label: 'Withdrawn' },
        { value: 'Advice', label: 'Advice' },
        { value: 'Cut', label: 'Cut' },
        { value: 'Completed', label: 'Completed' },
      ],
      default: { value: '', label: 'N/A' },
    },
    {
      key: 'statusDescription',
      label: 'Status description',
    },
  ],
};

export const SKIP_JOIN_METADATA_FIELDS = ['horizon_additionalData', 'horizon_longRatingsInfo']; //['horizon_NoteOnFile'];
export const RUNTIME_FIELDS = ['horizon_runtimeInSeconds', 'horizon_actualRuntimeInSeconds']; //['horizon_NoteOnFile'];
export const RUNTIME_FIELDS_V2 = ['runtimeInSeconds', 'actualRuntimeInSeconds'];
export const SPLIT_METADATA_FIELDS = ['horizon_genre'];
export const OBJECT_METADATA_FIELDS = ['horizon_longRatingsInfo', 'horizon_additionalData'];

export const TYPE_OF_MEDIA_VALUES = [
  'video',
  'film',
  'packaging',
  'vod',
  'videotrailer',
  'filmtrailer',
  'filmadvertisement',
  'subtv',
  'videoadvertisement',
  'digitalmedia',
  'aslive',
  'determination',
  'vam',
  'broadcastadvice',
  'season',
  'trailer',
  'series',
  'tv',
];

export const ALLOWED_TAG_LEVEL = 3;

export const PRICE_TYPES = {
  PER_MINUTE: 'Per minute',
  LOCATION_BASED: 'Location based',
  SET_COST: 'Set cost',
};

export const MODERN_CLASSIFICATION_OPTIONS = {
  pre89: 'The film was previously classified for cinema prior to 31 July 1989.',
  post89: 'The film was previously classified for cinema after 1 August 1989.',
};

export const VAL_HEADER_TAG_EDIT_MODES = {
  default: 'Strength & Description can be set',
  description: 'Only Description can be set',
  neither: 'Neither can be set',
};

export const REBOOK_RESULT_KEYS = {
  lriDelay: 'Delay publication',
  releaseDate: 'Release date',
  runtimeInSeconds: 'Runtime',
  contentDeliveryType: 'Content delivery type',
  publicationDate: 'Publication date',
};

export const BOOL_TO_TEXT = {
  true: 'Yes',
  false: 'No',
};

export const COMPLIANCE_REVIEW_PASSED_TAGS = [
  'COMPLIANCE_REVIEW',
  'COMPLIANCE_REVIEWED',
  'COMPLIANCE_REVIEW_CONFIRMATION',
  'COMPLIANCE_REVIEW_CONFIRMED',
  'COMPLIANCE_REVIEW_ACCEPTANCE',
  'COMPLIANCE_REVIEW_ACCEPTED',
  'PENDING_RESUBMISSION',
  'RESUBMISSION',
  'COMPLETED',
];

export const ASSET_LIST_MAP = {
  'Cinema Features': ['title', 'status', 'runtimeInSeconds', 'distributor', 'classificationDate', 'version', 'classification'],
  'Video Games': ['title', 'status', 'distributor', 'classificationDate', 'version', 'classification'],
  'Home Ent Features': ['title', 'status', 'runtimeInSeconds', 'distributor', 'classificationDate', 'version', 'classification'],
  VAM: ['episodeTitle', 'status', 'additionalTitle', 'runtimeInSeconds', 'classificationDate', 'classification'],
  Trailers: ['title', 'status', 'runtimeInSeconds', 'distributor', 'classificationDate', 'version', 'versionNumber', 'classification'],
  Packaging: ['title', 'status', 'distributor', 'classificationDate'],
  Episodes: ['episode', 'status', 'episodeTitle', 'runtimeInSeconds', 'shortFormInsight', 'classificationDate', 'classification'],
  Other: ['title', 'status', 'runtimeInSeconds', 'distributor', 'classificationDate', 'classification'],
  RelevantAsset: ['productPath', 'version', 'id', 'title', 'isRelevant', 'isRelevantOverride', 'approvedUsage'],
  RelevantAssetEpisodes: ['productPath', 'episode', 'version', 'id', 'title', 'isRelevant', 'isRelevantOverride'],
};

export const ASSET_NAME_MAP = {
  id: 'Id',
  title: 'Title',
  episodeTitle: 'Episode title',
  additionalTitle: 'Additional Title',
  runtimeInSeconds: 'Runtime',
  classificationDate: 'Classification Date',
  classification: 'Cert',
  version: 'Version',
  versionNumber: 'Version Number',
  shortFormInsight: 'Content advice', // Content advice was Short Ratings Info
  status: 'Status',
  distributor: 'Distributor',
  releaseDate: 'Release Date',
  episode: 'Episode',
  productPath: 'Product path',
  isRelevantOverride: 'Override',
  isRelevant: 'Relevant',
  approvedUsage: 'Usage',
};

export const PRODUCT_PATH_GENERATE_RATING_FROM_CINEMA = 'Generate rating from cinema';
export const VERSION_GENERATE_FROM_CLASSIFIED_CINEMA_VERSION = 'Generate from classified cinema version';

export const ADVICE_CINEMA_OFFER_TEXT = 'Advice submissions are viewed by our Compliance Managers and bookings are subject to their availability. Our projection department will be in touch to confirm the details of the screening and media delivery deadline.';
export const EXTERNAL_VENUE_OFFER_TEXT = 'Viewings in external venues are subject to staff availability. Our projection department will check availability and be in touch to confirm the details of the screening and media delivery deadline.';
export const STANDARD_CINEMA_OFFER_TEXT = 'The showing will not necessary take place on this date. Our projection department will be in touch to confirm the details of the screening and media delivery deadline.';
export const premiumCinemaOfferText = (timeMin) => `The screening has been scheduled for, ${moment(timeMin).format('Do MMM YYYY')}. Our projection department will be in touch to confirm the details of the screening and media delivery deadline.`;

export const FORMATTER = {
  toDate: value => (value ? moment(value).format('l') : null),
  toDateTime: value => (value ? moment(value).format(dateTimeFormat) : null),
  toGBP: value => (value ? new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(value) : null),
  toBool: value => {
    if (typeof value !== 'boolean') return null;
    return value ? 'Yes' : 'No';
  },
};

export const ASSET_DETAIL_META_MAP = {
  'Title Info': [
    { key: 'title', label: 'Title' },
    { key: 'AKATitle', label: 'AKA Title' },
    { key: 'additionalTitle', label: 'Additional Title' },
    { key: 'InsightSectionTitles', label: 'Insight Section Titles' },
    { key: 'Title', label: 'Horizon Title' },
    { key: 'TitleV', label: 'Title V' },
    { key: 'collection_title', label: 'Collection Title' },
    { key: 'versionName', label: 'Version Name' },
    { key: 'versionNumber', label: 'Version Number' },
    { key: 'seasonTitle', label: 'Season Title' },
    { key: 'ClientCode', label: 'Client Code' },
    { key: 'ClientCode_RightsHolder', label: 'Rights Holder' },
    { key: 'ClientCode_SubmittingCompany', label: 'Submitting Company' },
    { key: 'SubmittingCompany', label: 'Distributor' },
    { key: 'ScreenSubTitle', label: 'Screen sub title' },
    { key: 'ScreenTitle', label: 'Title on Screen' },
    { key: 'TVMETA_EP_SERIES_TITLE', label: 'EP Series Title' },
    { key: 'episodeTitle', label: 'Episode Title' },
    { key: 'TitleChange', label: 'Title Change', render: FORMATTER.toBool },
  ],
  'Ratings info': [
    { key: 'classification', label: 'Classification' },
    { key: 'SecondaryClassification', label: 'Secondary Classification', render: FORMATTER.toBool },
    { key: 'shortFormInsight', label: 'Content advice' },
    { key: 'longRatingsInfo', label: 'Extended Advice', type: 'longRatingsInfo' },
    { key: 'synopsis', label: 'Synopsis' },
    { key: 'nonDefiningIssues', label: 'Non Defining Issues' },
  ],
  'CO Reports': [
    { key: 'DateViewed', label: 'Date Viewed', render: FORMATTER.toDate },
    { key: 'TypeOfMedia', label: 'Type of Work' },
    { key: 'Examiners', label: 'Examiners' },
    { key: 'Viewing', label: 'Viewing' },
    { key: 'Author', label: 'Author' },
    { key: 'DateRaised', label: 'Date Written', render: FORMATTER.toDate },
    { key: 'synopsis', label: 'Brief Synopsis' },
    { key: 'ArchiveRemarks', label: 'Analysis and Assessment', type: 'html' },
    { key: 'FurtherAction', label: 'Further Action Required' },
    { key: 'RecommendedClass', label: 'Recommended Classification' },
    { key: 'reportId', label: 'Report ID' },
    { key: 'asset_id', label: 'Asset ID' },
    { key: 'nonDefiningIssues', label: 'Non Defining Issues' },
    { key: 'complianceComment', label: 'Compliance Comment', type: 'html' },
    { key: 'approvalComments', label: 'Approval Comments', type: 'html' },
    { key: 'reportCompletedDate', label: 'Report Completed Date', render: FORMATTER.toDateTime },
    { key: 'shortFormInsight', label: 'Content Advice' },
    { key: 'userDefiningIssues', label: 'User Defining Issues' },
    { key: 'classification', label: 'Classification' },
    { key: 'genre', label: 'Genre', render: (data) => (data || []).join(', ') },
    { key: 'cutComment', label: 'Cut Comment' },
    { key: 'reportApproved', label: 'Report Approved', render: FORMATTER.toBool },
    { key: 'reportConfirmed', label: 'Report Confirmed', render: FORMATTER.toBool },
    { key: 'created_by', label: 'Created By' },
    { key: 'modified_by', label: 'Modified By' },
    { key: 'created_on', label: 'Created On', render: FORMATTER.toDateTime },
    { key: 'updated_on', label: 'Updated On', render: FORMATTER.toDateTime },
    { key: 'deleted_on', label: 'Deleted On', render: FORMATTER.toDateTime },
    { key: 'longRatingsInfo', label: 'Extended Advice', type: 'longRatingsInfo' },
  ],
  'Viewing History': [
    { key: 'AgeCheck', label: 'Proof of age requested?' },
    { key: 'FlashingLetter', label: 'Flashing lights letter sent?' },
    { key: 'MonthRemarks', label: 'Website remarks' },
    { key: 'DateViewing', label: 'Viewing date', render: FORMATTER.toDate },
    { key: 'FullViewing', label: 'Viewed in full?', render: FORMATTER.toBool },
    {
      key: 'Examiners',
      label: 'Viewed by',
      render: (value) => {
        if (Array.isArray(value)) return value.join(', ');
        return value;
      },
    },
    { key: 'DateERApproval', label: 'Examiners Report Approved on' },
    { key: 'DateExaminationComplete', label: 'Examination Completed on', render: FORMATTER.toDate },
    {
      key: 'ExamAction',
      label: 'Examiner Action',
      render: (value) => {
        if (Array.isArray(value)) return value.join(', ');
        return value;
      },
    },
    { key: 'FurtherViewing', label: 'Referred for further viewing?' },
    { key: 'DateFurtherViewing', label: 'Further Viewing on', render: FORMATTER.toDate },
    { key: 'OtherExaminers', label: 'Who else viewed the work?' },
    { key: 'Referral', label: 'Referred to' },
    { key: 'DateRefer', label: 'Date Referred', render: FORMATTER.toDate },
    { key: 'RejectedBy', label: 'Rejected by' },
    { key: 'DateReject', label: 'Date Rejected', render: FORMATTER.toDate },
    { key: 'classificationDate', label: 'Date Passed', render: FORMATTER.toDate },
    { key: 'SectionsViewed', label: 'Sections viewed' },
    { key: 'VE1STDATE', label: 'First viewing date', render: FORMATTER.toDate },
    { key: 'Viewing', label: 'Number of viewings' },
    { key: 'AsCutOnFilm', label: 'As cut on film' },
    { key: 'TypeCuts', label: 'Type of cut' },
    { key: 'CutsDescription', label: 'Description of cuts' },
    { key: 'CutsListApproval', label: 'Cuts approved by' },
    { key: 'CutsReplaced', label: 'Cuts replaced?' },
    { key: 'CutVersion', label: 'Is this the cut version of the film?' },
    { key: 'DateCutsIssued', label: 'Date cuts Issued', render: FORMATTER.toDate },
    { key: 'DateCutsListApproval', label: 'Date cuts approved', render: FORMATTER.toDate },
    {
      key: 'FinalExaminers',
      label: 'Final Examiners',
      render: (value) => {
        if (Array.isArray(value)) return value.join(', ');
        return value;
      },
    },
    { key: 'Officer', label: 'Approved by' },
    { key: 'DatePassed', label: 'Date Approved', render: FORMATTER.toDate },
    { key: 'RequestedCatDetails', label: 'Requested category' },
    { key: 'ProposedCategory', label: 'Proposed Category' },
    { key: 'EMTCategory', label: 'EMT category' },
    { key: 'EMTAction', label: 'EMT action' },
    { key: 'EMTAdjudication', label: 'EMT adjudication' },
    { key: 'NoteOnFile', label: 'Notes on file', type: 'html' },
    { key: 'vh_rtt', label: 'VH RTT', type: 'vh' },
  ],
  'Additional Info': [
    { key: 'RightsHolder', label: 'Rights Holder' },
    { key: 'RightsHolderDCode', label: 'Rights Holder DCode' },
    { key: 'ScreenSubTitle', label: 'Title' },
    { key: 'ScreenTitle', label: 'Title on Screen' },
    { key: 'MainType', label: 'Submission Main Type' },
    { key: 'SubType', label: 'Submission Sub Type' },
    { key: 'statusDescription', label: 'Status description' },
    { key: 'iserveComment', label: 'Notes' },
    { key: 'ICFRemarks', label: 'ICF Remarks' },
    { key: 'Remarks', label: 'Remarks' },
    { key: 'PaperworkRemarks', label: 'Paperwork Remarks' },
    { key: 'PaperworkRemarksFree', label: 'Paperwork Remarks Free Text' },
    { key: 'InsightSpoilers', label: 'Spoilers in ratings info' },
    {
      key: 'producer',
      label: 'Producer',
      render: (value) => {
        if (Array.isArray(value)) return value.join(', ');
        return value;
      },
    },
    { key: 'season', label: 'Season' },
    { key: 'episode', label: 'Episode' },
    { key: 'episodeTitle', label: 'Episode Title' },
    { key: 'version', label: 'Version' },
    { key: 'BreakRemarks', label: 'Break Remarks' },
    { key: 'DateInterimSig', label: 'Interim Clearance Form received on', render: FORMATTER.toDate },
    { key: 'DateReleaseUK', label: 'UK Release Date', render: FORMATTER.toDate },
    { key: 'DateWithdrawn', label: 'Withdrawal Date', render: FORMATTER.toDate },
    { key: 'NoOfFilmReels', label: 'Number of film reels' },
    { key: 'Gauge', label: 'Film Gauge' },
    { key: 'AlreadyIn3D', label: 'Previously Passed In 3D?' },
    { key: 'Contains2Dversion', label: 'Contains 2D Version' },
    { key: 'PassedBytes', label: 'Passed Bytes' },
    { key: 'RecordStandard', label: 'Recording Standard' },
    { key: 'Colour', label: 'Colour' },
    { key: 'RecordFormat', label: 'Format' },
    { key: 'aspectRatio', label: 'Aspect Ratio' },
    { key: 'Ratio', label: 'Ratio' },
    { key: 'Sound', label: 'Sound' },
    { key: 'classificationDate', label: 'Classification Date', render: FORMATTER.toDate },
    { key: 'OtherLanguage', label: 'Other Language' },
    { key: 'OtherLanguages', label: 'Other Languages' },
    { key: 'DateEmbargo', label: 'Embargo Date', render: FORMATTER.toDate },
    { key: 'DateRelease', label: 'Date Release', render: FORMATTER.toDate },
    { key: 'releaseDate', label: 'Release Date', render: FORMATTER.toDate },
    { key: 'publicationDate', label: 'Publication Date', render: FORMATTER.toDate },
    { key: 'ProductionYear', label: 'Production Year' },
    { key: 'DateResub', label: 'Date Resubmitted', render: FORMATTER.toDate },
    {
      key: 'CompositeTitles',
      label: 'Composite Titles',
      render: (value) => {
        if (Array.isArray(value)) return value.join(', ');
        return value;
      },
    },
  ],
  'Viewing Info': [
    { key: 'FYITitle', label: 'FYI' },
    { key: 'MATCHESCODENUMBER', label: 'Matches' },
    { key: 'MatchesRegNumber', label: 'Matches' },
    { key: 'OtherMedium', label: 'Passed in another medium' },
    { key: 'RTFlags', label: 'Viewing info' },
    { key: 'MP4_URL', label: 'URL' },
    { key: 'savedURL', label: 'URL' },
    { key: 'savedUserName', label: 'Username' },
    { key: 'savedPW', label: 'Password' },
    { key: 'savedAccessDetails', label: 'Access details' },
    { key: 'platformUniqueID', label: 'Unique Platform ID' },
    { key: 'VODAdditional', label: 'VoD Additional details' },
    { key: 'VODDeliveryMethod', label: 'VoD Delivery Method' },
    { key: 'VODUSER', label: 'VoD Username' },
    { key: 'VODPW', label: 'VoD Password' },
  ],
  Archive: [
    {
      key: 'Barcodes',
      label: 'Barcodes',
      render: (value) => {
        if (Array.isArray(value)) return value.join(', ');
        return value;
      },
    },
    { key: 'BoxNumber', label: 'Deep Store Box' },
    { key: 'ComTapeNumber', label: 'Composite Tape Number' },
    { key: 'DateLabelled', label: 'Date Labelled', render: FORMATTER.toDate },
    { key: 'NoOfTapes', label: 'Number of Tapes' },
    { key: 'NumMedia', label: 'Number of Media Items' },
    { key: 'NumTapesArch', label: 'Number of Tapes Archived' },
    { key: 'PICKCodeNumber', label: 'Pick System Code Number (old ref)' },
    { key: 'Security', label: 'Barcode Number' },
    { key: 'Stored', label: 'Where Stored' },
    { key: 'TapeNumber', label: 'Tape Number' },
    { key: 'TapePosition', label: 'Tape Position' },
    { key: 'ArchiveRemarks', label: 'Archive Remarks' },
    { key: 'DateArch', label: 'Date Archived', render: FORMATTER.toDate },
    { key: 'DateDocArchivedOn', label: 'Date Archived On', render: FORMATTER.toDate },
    { key: 'DateArchOffSite', label: 'Date Archived Offsite', render: FORMATTER.toDate },
    { key: 'FragmentedWork', label: 'Fragmentation Work' },
    { key: 'FragCodeNumber', label: 'Fragmentation code number' },
    { key: 'FragmentOnDiscNo', label: 'Fragment on disc number' },
  ],
  ConAdv: [
    { key: 'ConAdvFilm', label: 'Film' },
    { key: 'ConAdvLanguage', label: 'Language' },
    { key: 'ConAdvSex', label: 'Sex' },
    { key: 'ConAdvTheme', label: 'Theme' },
    { key: 'ConAdvViolence', label: 'Violence' },
  ],
  'TV Info': [
    { key: 'tvmeta_series_name', label: 'Series Name', render: (value, metadata) => value || metadata.TVMETA_EP_SERIES_TITLE },
    { key: 'tvmeta_series_id', label: 'Series ID' },
    { key: 'season', label: 'Season No' },
    { key: 'episode', label: 'Episode No' },
    { key: 'TVMETA_EP_UNIQUEID', label: 'Episode Unique ID' },
    { key: 'tvmeta_ep_cat', label: 'Episode Category/Categories' },
    { key: 'TVMETA_EP_TIMECODE', label: 'Episode Runtime' },
    { key: 'tvmeta_ep_eci', label: 'Content advice' },
    { key: 'TVMETA_EP_TYPE', label: 'Type' },
    { key: 'TVMETA_EP_DISC', label: 'EP Disc' },
    { key: 'tvmeta_ep_marker', label: 'EP Marker' },
    { key: 'TVMETA_EP_VOLUME', label: 'EP Volume' },
  ],
  'Packaging Info': [
    { key: 'ApprovalType', label: 'VPRC Approval' },
    { key: 'ReasonCut', label: 'Reason for cuts' },
    { key: 'ArtworkForm', label: 'Form submitted' },
    { key: 'DateModification', label: 'Date modification letter sent', render: FORMATTER.toDate },
    { key: 'DateModificationRejection', label: 'Date rejection letter was sent', render: FORMATTER.toDate },
  ],
  'Compliance Work Details': [
    { key: 'WorkFormat', label: 'Format' },
    { key: 'VMFILENAME', label: 'Filename' },
    { key: 'VMrefno', label: 'Reference number' },
    { key: 'VMConfirmTime', label: 'Confirmed Runtime' },
    { key: 'VMCustomerRT', label: 'Customer runtime' },
    { key: 'VMruntime', label: 'Runtime' },
    { key: 'VMCutsInfo', label: 'Cuts information' },
    { key: 'VMDatePassed', label: 'Date passed', render: FORMATTER.toDate },
    { key: 'VMOfficer', label: 'Compliance officer' },
    { key: 'VMProposedCategory', label: 'Proposed category' },
    { key: 'VMEMTCategory', label: 'Compliance manager category' },
  ],
  'Sky tags': [
    { key: 'skyWarningLanguage', label: 'Language' },
    { key: 'skyWarningMature', label: 'Mature' },
    { key: 'skyWarningSexNudity', label: 'Sex/Nudity' },
    { key: 'skyWarningViolence', label: 'Violence' },
  ],
  'Submission data': [
    { key: 'additionalData.tags', label: 'Tags' },
    { key: 'additionalData.price', label: 'Price', render: FORMATTER.toGBP },
    { key: 'additionalData.priceBand', label: 'Price band' },
    { key: 'additionalData.cutOffDate', label: 'Requested Classification Date', render: FORMATTER.toDate },
    { key: 'additionalData.viewingDate', label: 'Viewing Date', render: FORMATTER.toDate },
    { key: 'additionalData.paymentSettled', label: 'Payment settled?', render: FORMATTER.toBool },
    { key: 'additionalData.numberOfLocations', label: 'Polled locations' },
    { key: 'additionalData.compositeElements', label: 'Composite elements' },
    { key: 'additionalData.requiredClassificationDueDate', label: 'Required Classification Due Date', render: FORMATTER.toDate },
    { key: 'additionalData.dateOfInvoice', label: 'Date Of Invoice', render: FORMATTER.toDate },
    { key: 'additionalData.invoiceDueDate', label: 'Invoice Due Date', render: FORMATTER.toDate },
    { key: 'additionalData.invoiceNumber', label: 'Invoice Number' },
    { key: 'additionalData.invoicingContactName', label: 'Invoice Contact' },
    { key: 'additionalData.deliveryCompletedAt', label: 'Delivery Completed Date', render: FORMATTER.toDate },
    { key: 'additionalData.reportCompletedDate', label: 'Report Completed Date', render: FORMATTER.toDate },
    { key: 'additionalData.approvedBy', label: 'Approved By' },
    { key: 'additionalData.viewedBy', label: 'Viewed By' },
    { key: 'csNotes', label: 'CS Notes' },
  ],
  'Netflix Info': [
    { key: 'reviewSource', label: 'ReviewSource' },
    { key: 'NetflixApplicationID', label: 'ApplicationID' },
    { key: 'NetflixApplicationStatus', label: 'ApplicationStatus' },
    { key: 'NetflixApplicationUser', label: 'ApplicationUser' },
    { key: 'NetflixAuditedURL', label: 'AuditedURL' },
    { key: 'NetflixApplicationDateModifiedUTC', label: 'ApplicationDateModified', render: FORMATTER.toDateTime },
    { key: 'NetflixProductionID', label: 'ProductionID' },
    { key: 'NetflixCategory', label: 'Category' },
    { key: 'NetflixTitleExternalID', label: 'TitleExternalID' },
    { key: 'NetflixProductionTitleInformation', label: 'ProductionTitleInformation' },
    { key: 'NetflixProductionCompanyName', label: 'ProductionCompanyName' },
    { key: 'NetflixReleaseYear', label: 'ReleaseYear' },
    { key: 'NetflixProductionDateModifiedUTC', label: 'ProductionDateModified', render: FORMATTER.toDateTime },
    { key: 'NetflixProductionUser', label: 'ProductionUser' },
    { key: 'NetflixEpisodeID', label: 'EpisodeID' },
    { key: 'NetflixTotalEpisodeCount', label: 'TotalEpisodeCount' },
    { key: 'NetflixEpisodeAuditURL', label: 'EpisodeAuditURL' },
    { key: 'NetflixEpisodeDateModifiedUTC', label: 'EpisodeDateModified', render: FORMATTER.toDateTime },
    { key: 'NetflixEpisodeUser', label: 'EpisodeUser' },
    { key: 'NetflixRatingDateModifiedUTC', label: 'RatingDateModified', render: FORMATTER.toDateTime },
    { key: 'NetflixRatingID', label: 'RatingID' },
    { key: 'NetflixRatingUser', label: 'RatingUser' },
    { key: 'NetflixAuditID', label: 'AuditID' },
    { key: 'NetflixSelectedForAudit', label: 'SelectedForAudit' },
    { key: 'NetflixAuditedRatingCode', label: 'AuditedRatingCode' },
    { key: 'NetflixAuditedRatingInsight', label: 'AuditedRatingInsight' },
    { key: 'NetflixAuditedCuts', label: 'AuditedCuts' },
    { key: 'NetflixAuditURL', label: 'AuditURL' },
    { key: 'NetflixAuditDateModifiedUTC', label: 'AuditDateModified', render: FORMATTER.toDateTime },
    { key: 'NetflixAuditStatus', label: 'AuditStatus' },
    { key: 'NetflixAuditUser', label: 'AuditUser' },
    { key: 'NetflixScreenResolution', label: 'ScreenResolution' },
  ],
};

export const ASSET_STATUS_VALUES = [
  { value: 'Pending', label: 'Pending' },
  { value: 'Dead', label: 'Dead' },
  { value: 'Backlog', label: 'Backlog' },
  { value: 'Rejected', label: 'Rejected' },
  { value: 'Withdrawn', label: 'Withdrawn' },
  { value: 'Advice', label: 'Advice' },
  { value: 'Cut', label: 'Cut' },
  { value: 'Completed', label: 'Completed' },
];

const constants = {
  NOTIFICATION_VARIANTS,
};

export const CARD_SIZE = {
  [ASPECT_RATIOS.FLAT]: { width: 912, height: 493 },
  [ASPECT_RATIOS.STANDARD]: { width: 912, height: 680 },
  [ASPECT_RATIOS.SCOPE]: { width: 912, height: 383 },
};

export const arrayExceptionFieldKeys = [
  'status',
  'genre',
];

export default constants;
