import { isEqual, pickBy } from 'lodash';
import { arrayExceptionFieldKeys, SKIP_JOIN_METADATA_FIELDS } from './constants';

const mapMetadata = (field) => {
  const { value } = field;
  return value.map((val) => val.value);
};

export const resolveMetadata = (item) => {
  if (
    !item.metadata ||
    !item.metadata.timespan ||
    !item.metadata.timespan.length
  ) {
    return {};
  }

  const metadata = {};
  const fields = item.metadata.timespan[0].field;
  fields.forEach((field) => {
    if (field.value) {
      const value = mapMetadata(field);
      if (SKIP_JOIN_METADATA_FIELDS.includes(field.name)) {
        const [firstValue] = value;
        metadata[field.name] = firstValue;
        return;
      }
      metadata[field.name] = value.join(', ');
    }
  });

  return metadata;
};

export const resolveGroupMetadata = (item) => {
  if (!item.metadata || !item.metadata.timespan.length) {
    return {};
  }
  if (
    !item.metadata.timespan[0].group ||
    !item.metadata.timespan[0].group.length
  ) {
    return {};
  }

  const groups = [];
  item.metadata.timespan[0].group.forEach((group) => {
    const groupInfo = { name: group.name };
    group.field.forEach((field) => {
      if (field.value) {
        const value = mapMetadata(field);
        if (SKIP_JOIN_METADATA_FIELDS.includes(field.name)) {
          const [firstValue] = value;
          groupInfo[field.name] = firstValue;
          return;
        }
        groupInfo[field.name] = value.join(', ');
      }
    });

    groups.push(groupInfo);
  });

  return groups;
};

// database has inconsistent data and sometimes returns arrays as string
export const getValue = (value) => {
  if (Array.isArray(value)) {
    return value
      .join(',')
      .split(',')
      .map((e) => e.trim());
  }
  if (typeof value === 'string') {
    return value.split(',').map((e) => e.trim());
  }
  if (value === undefined) return [];
  return value;
};

// database has inconsistent data and sometimes returns strings as array
export const getSingleValue = (value) => {
  if (Array.isArray(value)) return value.join(',');
  if (value === undefined) return '';
  if (value === null) return '';
  return value.toString();
};

export const transformUpdateValue = (value) => {
  if (value === '') return null;
  if (Array.isArray(value) && value.length > 0) {
    const filteredValue = value.filter(item => item !== '');
    return filteredValue.length > 0 ? filteredValue : null;
  }
  return value;
};

export const transformMetadataToComponent = (metaData, sectionsWithFields) => {
  const result = {};
  Object.entries(sectionsWithFields).forEach(([_, section]) => {
    section.forEach((field) => {
      if (['multiSelect', 'select'].includes(field.type)) {
        result[field.key] = getValue(metaData[field.key]);
      } else {
        result[field.key] = getSingleValue(metaData[field.key]);
      }
    });
  });
  return result;
};

export const getChangedFields = (updatedValues, validMetaData) => {
  const updatedData = { ...validMetaData, ...updatedValues };
  return pickBy(updatedData, (value, k) => !isEqual(value, validMetaData[k]));
};

const isNonEmptyArray = (value) => Array.isArray(value) && value.length > 0;

const formatArrayValue = (key, value) => {
  if (arrayExceptionFieldKeys?.includes(key)) return value;
  return value.join(',');
};

export const transformChangedFieldsToDb = (changedFields) => {
  const formattedChangeList = {};

  for (const [key, value] of Object.entries(changedFields)) {
    if (Array.isArray(value)) {
      formattedChangeList[key] = isNonEmptyArray(value) ? formatArrayValue(key, value) : null;
    } else {
      formattedChangeList[key] = value;
    }
  }

  return formattedChangeList;
};
